@import "../../../breakpoints";

.odtOverview{
    &-head{
        background-image: linear-gradient(#ffffff, #E5E9F1);
        display: flex;
        align-items: center;

        @media (max-width: $breakPoint4) {
            flex-direction: column;
        }
    }
    &-headline {
        width: 40%;
    }
    &-searchBar{
        text-align: start;
        margin-left: auto;

        @media (max-width: $breakPoint4) {
            margin: 0 auto;
            padding-bottom: 30px;
        }

        input{
            background-color: transparent;
            color: #525260;
            outline: none;
            outline-style: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: solid #d0cfd6 1px;
            padding: 3px 10px;
        }
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
    }

    &-searchButton {
        margin-top: 5px;
        background-color:#002878;
        color: white;
        border: none;
        border-radius: 50px;
        padding: 5px 15px;

        &:hover {
            background-color: rgb(55, 84, 143);
        }
    }
    &-clearButton {
        border: none;
        background: transparent;
        margin-left: auto;
    }

    &-additional-info {
        text-overflow: ellipsis;
        overflow: hidden;
    
        &:hover {
            overflow: visible;
            background-color: inherit;
            white-space: normal;
            height: auto;
        }
    }
    
    &-body{

        &-arrow{
            position:absolute;
            width:12px;
            height:12px;
            background-color:#ffffff;
            left:50%;
            margin-top:-6px;
            margin-left:-6px;
            transform: rotate(45deg);
        }
        &-link{
            cursor:pointer;
            text-decoration:underline;
        }

        &-trHidden{
            td{
                opacity:0.2;
            }
        }


        table{
            table-layout: fixed;
            width:calc(100% + 4000px);
            max-width:calc(100% + 4000px);
            border-collapse: collapse;
            margin-left:-2000px;
            margin-right:-2000px;
            
            tr:nth-child(odd){
                background-color:rgba(229,233,241, 0.1);
            }
            tr:first-child{
                background-color:transparent;
            }
            th{
                text-align:left;
                height:40px;
                border-bottom: 2px #E5E9F1 solid;
                white-space: nowrap;
                padding-left:5px;
                padding-right:5px;
                color:#002878;
            }
            td{
                height:40px;
                padding-left:5px;
                padding-right:5px;
                white-space: nowrap;
                color: #525260;
            }
            td:nth-child(1), th:nth-child(1){
                padding-left:2000px;

                width:160px;
                @media (max-width: $breakPoint5) {
                    width:130px;
                }
                @media (max-width: $breakPoint4) {
                    width:105px;
                }
                @media (max-width: $breakPoint3) {
                    width:80px;
                }
                @media (max-width: $breakPoint2) {
                    width:55px;
                }
            }
            td:nth-child(2), th:nth-child(2){
                 width:190px;
                @media (max-width: $breakPoint5) {
                    width:160px;
                }
                @media (max-width: $breakPoint4) {
                    width:130px;
                }
                @media (max-width: $breakPoint3) {
                    width:100px;
                }
                @media (max-width: $breakPoint2) {
                    width:75px;
                }
            }
            td:nth-child(3), th:nth-child(3){
                text-overflow: ellipsis;
                overflow: hidden;
            }
            td:nth-child(4), th:nth-child(4){
                width:360px;

                @media (max-width: $breakPoint5) {
                    width:300px;
                }
                @media (max-width: $breakPoint4) {
                    width:250px;
                }
                @media (max-width: $breakPoint3) {
                    width:200px;
                }
                @media (max-width: $breakPoint2) {
                    width:145px;
                }
            }
            td:nth-child(5), th:nth-child(5){
                text-align:right;
                padding-right:2000px;
                width:60px;
            }
        }
    }

    &-foot{
        background-image: linear-gradient(#E5E9F1, #ffffff);
    }
}
