@mixin dmButton($color:#002878, $hoverColor:#7f93bb) {
    display: inline-block;
    font-family: dmbrand-medium;
    font-size: 15px;
    line-height: 40px;
    color: #ffffff;
    background-color: $color;
    height:40px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 60px;
    border-radius:20px;
    cursor: pointer;
    text-decoration:none;
    &:hover{
        background-color: $hoverColor;
    }
}
