@import "../../../responsive";

.odtDetail{
    &-head{
        background-image: linear-gradient(#ffffff, #E5E9F1);
    }
    &-body{
        a{
            color:#525260 !important;
        }
        &-margin{
            margin-left:-35px;
            margin-right:-35px;
        }

        background-color:rgba(229,233,241, 0.1);
        table{
            border-collapse: collapse;
        }
        &-arrow{
            position:absolute;
            width:12px;
            height:12px;
            background-color:#ffffff;
            left:50%;
            margin-top:-6px;
            margin-left:-6px;
            transform: rotate(45deg);
        }
        &-spacer{
            @include responsiveCols(1,0.5,0,0,0);
        }
        &-box1{
            @include responsiveCols(3,3,3,2,1);

            vertical-align:top;

            position:relative;
            box-sizing: border-box;
            table{
                width:100%;
            }
        }
        &-box2{
            @include responsiveCols(1.5,1.5,1.5,2,1);

            vertical-align:top;

            position:relative;
            box-sizing: border-box;
            table{
                width:100%;
            }
        }
        &-box2-spacing{
            @media (max-width: $breakPoint3 - 1) {
                padding-top:20px;
            }
        }

    }
    &-foot{
        background-image: linear-gradient(#E5E9F1, #ffffff);
    }
    .odt-button, .odt-button-red{
        margin-bottom:10px;
    }
}
