@import "../../../responsive";

.odtOrderItem{
    padding-top:30px;
    padding-bottom:10px;
    background-color:white;
    border-top:2px #E5E9F1 solid;

    &-spacer{
        @include responsiveCols(1,0.5,0,0,0);
    }

    &-image{
        @include responsiveCols(0.5,0.5,0.5,0.5,0.33333);
        vertical-align:top;
        padding-left:10px !important;
        padding-right:10px !important;
        text-align:center;
        img{
            max-width:100px;
            max-height:100px;
        }
        @media (max-width: $breakPoint3 - 1) {
            padding-left:35px !important;
            text-align:left;
            padding-right:0px !important;
            width:135px;
        }
    }

    &-textblock1{
        @include responsiveCols(1,1,1,1.5,0.66666);
        vertical-align:top;
        @media (max-width: $breakPoint3 - 1) {
            width:calc(100% - 135px);
            padding-left:20px !important;
        }
    }

    &-textblock2{
        @include responsiveCols(1.5,1.5,1.5,1.5,0.66666);
        vertical-align:top;
        @media (max-width: $breakPoint3 - 1) {
            width:calc(100% - 135px);
            padding-left:20px !important;
            padding-top:20px;
        }
    }

    &-textbock2-spacer{
        @include responsiveCols(0,0,0,0.5,0.33333);
        @media (min-width: $breakPoint3) {
           display:none
        }
        @media (max-width: $breakPoint3 - 1) {
            width:135px;
            padding-right:0px !important;
        }
    }

    &-text{
        font-family:dmbrand-regular;
        font-size:12px;
        color:#525260;
        line-height:1.2;
    }
    &-title{
        font-family:dmbrand-medium;
        font-size:16px;
        color:#525260;
        line-height:1.2;

    }
    &-table{
        position:relative;
    }

  /*  &-tableLClickable{
        font-family:dmbrand-medium;
        font-size:16px;
        color:#002878;
        line-height:1.2;
        width:50%;
    }*/

   /* &-tableRClickable{
        position:absolute;
        top:0px;
        left:50%;
        width:50%;
        text-align:right;
        font-family:dmbrand-regular;
        font-size:16px;
        color:#002878;
        line-height:1.2;
        &-bold{
            font-family:dmbrand-medium;
        }
    }*/

    &-tableL{
        font-family:dmbrand-medium;
        font-size:16px;
        color:#002878;
        line-height:1.2;
        width:100%;
    }

    &-tableR{
        position:absolute;
        top:0px;
        left:0%;
        width:100%;
        text-align:right;
        font-family:dmbrand-regular;
        font-size:16px;
        color:#002878;
        line-height:1.2;
        &-bold{
            font-family:dmbrand-medium;
        }
    }

   /* &-link{
        font-family:dmbrand-regular;
        font-size:12px;
        color:#002878;
        line-height:1.2;
        text-decoration:underline;
        cursor:pointer;
        display:inline-block;

    }*/
}
