@import "../../../breakpoints";

.odtStickyBar {
    height:60px;

    &-sticky{
        position:fixed;
        z-index:1000;
        width:100%;
        background-color: #f3f4f8;
        height:60px;
    }

    &-content{
        height:60px;
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items : center;
        position:relative;
    }

    &-contentItem{
        justify-content: center;
        display:inline-block;
    }

    &-logo{
        display:inline-block;
        img{
            width:156px;
        }
        @media (max-width: $breakPoint2) {
            img{
                width:100px;
            }
        }
        cursor:pointer;
    }

    &-buttonBack{
        margin-right:40px;


        @media (max-width: $breakPoint2) {
            margin-right:20px;
        }
    }

    .odtLogin{
        display:inline-block;
    }

}
