$color:#525260;
$arrowColor:#d0cfd6;
$hoverColor:#002878;

.odtPagination{
    &-item{
        display:inline-block;
        margin-left:2px;
        margin-right:2px;
        font-family: dmbrand-medium;
        font-size:16px;
        color:$color;
        font-family: dmsupport-regular;
        font-size:14px;

        width:21px;
        height:21px;
        padding-top:1px;
        text-align:center;
    }
    &-clickable{
        cursor:pointer;
        &:hover{
            color:$hoverColor;
        }
    }
    &-active{
        font-family: dmsupport-bold;

        border-radius:50%;
        background-color:$hoverColor;
        color:white;
    }
    &-arrow{
        position:relative;
        top:7px;
        path{
            cursor:pointer;
            stroke:$arrowColor;
        }
        &:hover{
            path {
                stroke:$hoverColor;
            }
        }
    }
}
