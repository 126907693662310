@import "./mixins";
@import 'webfonts/dm.css';

body{
    overflow-y: scroll; //always show scrollbar, avoids horizontal jumping content
}

.App{
    overflow-x:hidden;
    color: #525260;

    h2{
        font-family:dmbrand-regular;
        font-size:28px;
        color:#002878;
        font-weight:400;
        padding:0;
        margin:0;
    }
    h3{
        font-family:dmbrand-medium;
        font-size:18px;
        color:#002878;
        font-weight:400;
        padding:0;
        margin:0;
    }
    p{
        font-family:dmsupport-regular;
        color:#525260;
        font-size:16px;
        font-weight:400;
        padding:0;
        margin:0;
    }

    .odt{
        &-button{
            @include dmButton();
        }
        &-button-red{
            @include dmButton(#bb0000, #ff0000);
        }
        &-centered{
            text-align:center;
        }
        &-right{
            text-align:right;
        }
        &-smallTitle{
            font-family: dmbrand-medium;
            font-size: 16px;
            color: #002878;
        }
        &-smallText{
            font-family: dmbrand-regular;
            font-size:12px;
        }
        &-extendedBackground{
            margin-left:-2000px;
            margin-right:-2000px;
            padding-left:2000px;
            padding-right:2000px;
        }
        &-textButton{
            font-family:dmbrand-medium;
            font-size:16px;
            color:#002878;
            display:inline-block;
            cursor:pointer;
        }
        &-defaultLayout{
            margin-left:auto;
            margin-right:auto;
            width:100%;
            max-width:1180px;
            &-inner{
                padding-left:25px;
                padding-right:25px;
            }
        }
        &-icon{
            display:inline-block;
            width:20px;
            height:20px;
            cursor:pointer;
            &-download{
                background-color:red;
                background: url("assets/icon-download.svg");
                &:hover{
                    background: url("assets/icon-download-hover.svg");
                }
            }
            &-details{
                background-color:green;
                background: url("assets/icon-details.svg");
                &:hover{
                    background: url("assets/icon-details-hover.svg");
                }
            }
            &-delete {
                background-color:blue;
                background: url("assets/icon-delete.svg");
                &:hover{
                    background: url("assets/icon-delete-hover.svg");
                }
            }
        }
        &-errorMessage{
            padding-left:35px;
            padding-right:35px;
            text-align:center;
        }
        &-flexRow {
            display: flex;
            align-items: center;
            margin-bottom: 3px;
        }
    }
}
