
$disable123: false;

$breakPoint2: 480px;
$breakPoint3: 720px;
$breakPoint4: 960px;
$breakPoint5: 1200px;
@if ($disable123){
  $breakPoint2: 0px;
  $breakPoint3: 0px;
  $breakPoint4: 0px;
}


@mixin responsiveCols($layout5:5, $layout4:4, $layout3:3, $layout2:2, $layout1:1, $padding:35px, $verticalAlign:middle) {

  position:relative;
  width:#{20*$layout5+"%"};
  display:inline-block;
  //margin-right:-4px;
  vertical-align:$verticalAlign;

  @if $layout5 > 0 {
    padding-left:$padding;
    padding-right:$padding;
  } @else {
    padding-left:0;
    padding-right:0;
  }
  box-sizing:border-box;

  @media (max-width: $breakPoint5 - 1) {
    width:#{25*$layout4+"%"};
    @if $layout4 > 0 {
      padding-left:$padding;
      padding-right:$padding;
    } @else {
      padding-left:0;
      padding-right:0;
    }
  }
  @media (max-width: $breakPoint4 - 1) {
    width:#{33.33333*$layout3+"%"};
    @if $layout3 > 0 {
      padding-left:$padding;
      padding-right:$padding;
    } @else {
      padding-left:0;
      padding-right:0;
    }
  }
  @media (max-width: $breakPoint3 - 1) {
    width:#{50*$layout2+"%"};
    @if $layout2 > 0 {
      padding-left:$padding;
      padding-right:$padding;
    } @else {
      padding-left:0;
      padding-right:0;
    }
  }
  @media (max-width: $breakPoint2 - 1) {
    width:#{100*$layout1+"%"};
    @if $layout1 > 0 {
      padding-left:$padding;
      padding-right:$padding;
    } @else {
      padding-left:0;
      padding-right:0;
    }
  }
}
