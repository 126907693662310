@font-face {
    font-family: 'dmbrand-bold';
    src: url('../assets/fonts/dmbrand-bold-western.woff2') format('woff2'), url('../assets/fonts/dmbrand-bold-western.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'dmbrand-medium';
    src: url('../assets/fonts/dmbrand-medium.woff2') format('woff2'), url('../assets/fonts/dmbrand-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'dmbrand-regular';
    src: url('../assets/fonts/dmbrand-regular-western.woff2') format('woff2'), url('../assets/fonts/dmbrand-regular-western.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dmbrand-light';
    src: url('../assets/fonts/dmbrand-light-western.woff2') format('woff2'), url('../assets/fonts/dmbrand-light-western.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'dmsupport-bold';
    src: url('../assets/fonts/dmsupport-bold-western.woff2') format('woff2'), url('../assets/fonts/dmsupport-bold-western.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'dmsupport-medium';
    src: url('../assets/fonts/dmsupport-medium.woff2') format('woff2'), url('../assets/fonts/dmsupport-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'dmsupport-regular';
    src: url('../assets/fonts/dmsupport-regular-western.woff2') format('woff2'), url('../assets/fonts/dmsupport-regular-western.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dmsupport-italic';
    src: url('../assets/fonts/dmsupport-italic-western.woff2') format('woff2'), url('../assets/fonts/dmsupport-italic-western.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'dmsupport-light';
    src: url('../assets/fonts/dmsupport-light-western.woff2') format('woff2'), url('../assets/fonts/dmsupport-light-western.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
